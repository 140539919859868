import React from 'react';

import bgImg from '../../images/main_img.webp';
import guelLogo from '../../logo/guelLogo.webp';
import leWebsites from '../../logo/leWebsites.png';
import '../../fontawesome/css/all.min.css';
import '../../bootstrap/css/bootstrap.min.css';

import {Link as LinkR} from 'react-router-dom';

const Home = () => {

  return(
    <html>
      <body> 
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
            <div class="logo me-auto">
                <img src={guelLogo} alt="guel dienstleistungen logo" class="img-fluid"/>
            </div>
            <nav id="navbar" class="navbar order-last order-lg-0">
                <ul>
                <li><a class="nav-link scrollto" href="#about">Über Uns</a></li>
                <li><a class="nav-link scrollto" href="#services">Dienstleistungen</a></li>
                <li><a class="nav-link scrollto" href="#contact">Kontakt</a></li>
                </ul>
            </nav>
            </div>
        </header>
        <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
            <div class="container text-center text-md-left">
               <img src={guelLogo} alt="guel dienstleistungen" class="img-fluid"/>
              <br/>
              <a href="#about" class="btn-get-started scrollto">Erfahre mehr</a>
            </div>
          </section> 
        <main id="main"> 
            <section id="home" class="home">
                <div class="container">
                  <div class="section-title">
                    <h2>Wer wir sind</h2>
                    <p>Herzlich willkommen auf "Gül-Dienstleistungen", Ihr Fachbetrieb für Businnenreinigung, Büro/Praxenreinigung, Fensterreinigung, Winterdienst, Kehrwoche, Bauendreinigung, Unterhaltsreinigung, Grundreinigung, Bodenpflege, Hausservice, Teppich- und Polsterreinigung.</p>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch" >
                      <div class="icon-box">
                        <div class="icon"><i class="far fa-building"></i></div>
                        <h3>GEBÄUDEREINIGUNG AUS ESSLINGEN</h3>
                        <p>Die "Gül-dienstleistungen" mit Sitz in Esslingen am Neckar ist seit 2009 ein familiengeführtes Unternehmen in der Gebäudereinigung. Unser Unternehmen hat es sich zum Ziel gesetzt, durch eine besondere Qualität zu überzeugen. Unser Team ist der Schlüssel zum Erfolg.</p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" >
                      <div class="icon-box">
                        <div class="icon"><i class="far fa-thumbs-up"></i></div>
                        <h3>UNSERE KUNDEN SIND QUALITÄT GEWOHNT</h3>
                        <p>Fachkompetenz, geschultes Personal, Kundenbetreuung, Service und ein eine gute Beratung ist für uns die Voraussetzung für die gute Zusammenarbeit mit unseren Kunden. Dafür setzten wir eigene Maßstäbe.</p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" >
                      <div class="icon-box">
                        <div class="icon"><i class="fas fa-child"></i></div>
                        <h3>MITARBEITER AN ERSTER STELLE</h3>
                        <p>Die Philosophie unseres Unternehmens liegt in der Wertschätzung und Anerkennung unserer Mitarbeiter. Sie schaffen die langjährige, vertrauensvolle Zusammenarbeit mit unseren Kunden. Ein motiviertes Team mit Freude an der Arbeit stützt das Unternehmen und wir stützen es. Dabei ist die leistungsgerechte und tariftreue Bezahlung für uns selbstverständlich.</p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" >
                        <div class="icon-box">
                          <div class="icon"><i class="far fa-comments"></i></div>
                          <h3>KOSTENLOSES ANGEBOT</h3>
                          <p>Gerne beraten wir Sie vor Ort und erstellen Ihnen ein kostenloses und unverbindliches Angebot. 
                            Unser Service wird von unseren werten Kunden sehr geschätzt. Wir freuen uns auch Sie demnächst als unseren Kunden begrüßen zu dürfen.</p>
                        </div>
                      </div>
                  </div>
                </div>
              </section>
              <section id="about" class="about">
                <div class="container">
                    <div class="section-title">
                        <h2>Über Uns</h2>
                    </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <img src={bgImg} class="img-fluid" alt="guel-team" loading="lazy"/>
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0">
                      <p>
                        Unsere Firma wurde im Jahr 2009 als Einzelfirma gegründet. Sie beschäftigt Mitarbeiter in den Bereichen Industrie- und Gebäudereinigung sowie Hausmeisterservice.
                      </p>
                      <ul>
                        <li><i class="far fa-check-circle"></i> Als Unternehmen betreuen wir seit Jahren vertrauensvoll zahlreiche Kunden. Als Familienunternehmen sichern wir uns Unabhängigkeit, Flexibilität und Kontinuität. Wir setzen auf Teamarbeit. Gegenseitige Unterstützung ist bei uns selbstverständlich.</li>
                        <li><i class="far fa-check-circle"></i> Unsere Mitarbeiter verfügen über sehr gute fachliche Kenntnisse. Aus diesem Grund garantieren wir Ihnen stets eine qualitativ hochwertige und zuverlässige Ausführung Ihrer Aufträge. Die Zufriedenheit unserer Kunden steht bei uns an erster Stelle.</li>
                        <li><i class="far fa-check-circle"></i> "Gül-Dienstleistungen" bietet Ihnen einen Rundum-Service von der Gebäudereinigung über die Glasreinigung bis hin zum Winterdienst. Sie können sicher sein, dass Sie in guten Händen sind.</li>
                        <li><i class="far fa-check-circle"></i> Durch die kompetente Betreuung vor Ort können Ihre Wünsche und Vorstellungen präzise und schnell umgesetzt werden. Eine persönliche Beratung gehört genauso zu unseren Dienstleistungen wie ein kostenloses und unverbindliches Angebot.</li>
                      </ul>
                      <p>
                        Diese Internetseite soll Ihnen einen kleinen Einblick in unser Unternehmen und unsere Arbeit geben. Lassen Sie sich von uns überzeugen.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section id="team" class="team">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="member">
                        <h1>Hüseyin Gül</h1>
                        <span>Geschäftsführer</span>
                        <div class="social">
                          <a href="tel:+491742148628"><i class="fas fa-mobile-alt"></i> +49 174 2148628</a>
                          <br/>
                          <a href="mailto:h.guel@guel-dienstleistungen.de"><i class="far fa-envelope"></i> h.guel@guel-dienstleistungen.de</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="member">
                        <h1>Gülser Gül</h1>
                        <span>Bereichsleitung</span>
                        <div class="social">
                          <a href="tel:+491736741448"><i class="fas fa-mobile-alt"></i> +49 173 6741448</a>
                          <br/>
                          <a href="mailto:g.guel@guel-dienstleistungen.de"><i class="far fa-envelope"></i> g.guel@guel-dienstleistungen.de</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="services" class="services section-bg">
                <div class="container">
                  <div class="section-title">
                    <h2>Dienstleistungen</h2>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-4">
                        <div class="icon-box">
                            <i class="fas fa-bus"></i>
                          <h2>Businnenreinigung</h2>
                          <p>Speziell in der Businnenreinigung haben wir mehrjährige Erfahrung. 
                            Durch die kooperative Zusammenarbeit mit den Verkehrsgesellschaften haben wir uns auf die 
                            Businnenreinigung spezialisiert und praktizieren diese zur Zufriedenheit unseres Kunden.</p>
                        </div>
                      </div>
                    <div class="col-md-6 mt-4">
                      <div class="icon-box">
                        <i class="fab fa-windows"></i>
                        <h2>Fensterreinigung</h2>
                        <p>Die Reinigung Ihrer Fenster, sollten Sie am besten einem Profi überlassen. 
                            Sparen Sie Zeit, Nerven und unnötige Personalkosten. Ein Fensterputzer erledigt die Arbeit profesionell, günstig und schnell. 
                            Nach unserer Fensterreinigung sind Ihre Fenster garantiert Schlierenfrei. 
                            Erfahren Sie wie die Fensterreinigung funktioniert.</p>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <div class="icon-box">
                        <i class="fas fa-building"></i>
                        <h2>Büro- und Praxisreinigung</h2>
                        <p>Ein sauberes Arbeitsumfeld trägt maßgeblich zu Ihrem Wohlbefinden und dem Ihres Teams bei. 
                            Wir reinigen Ihre Kanzlei, Praxis oder Ihr Büro, um Ihnen dieses Umfeld zu schaffen. 
                            Somit können Sie sich gänzlich auf Ihre Kunden, Patienten oder Mandanten konzentrieren.</p>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <div class="icon-box">
                        <i class="fas fa-broom"></i>
                        <h2>Kehrwoche</h2>
                        <p>Die Kehrwoche steht wieder vor der Tür? <br/>
                            Wir übernehmen gerne die Kehraufgaben für Mieter und Vermieter – schnell, professionell und zuverlässig.</p>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                      <div class="icon-box">
                        <i class="fas fa-hard-hat"></i>
                        <h2>Bauendreinigung</h2>
                        <p>Wenn Ihr Haus saniert wurde oder einen neuen Anstrich bekommen hat oder auch komplett neu gebaut wurde, 
                            ist der Hausherr meist immer zufrieden. 
                            Doch wenn er dann einmal seinen Blick schweifen lässt, sieht das Gesamtbild eher unsauber aus und es liegt noch viel Müll rum, 
                            eventuell sogar noch etwas von den Baustoffen. Genau hier kommt die Bauendreinigung ins Spiel!</p>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="icon-box">
                          <i class="fas fa-home"></i>
                          <h2>Unterhaltsreinigung</h2>
                          <p>Die Unterhaltsreinigung ist ein wichtiger Beitrag zum langfristigen Werterhalt von Immobilien. 
                            Gül-Dienstleistungen gewährleistet mit individuell erarbeiteten Leistungsverzeichnissen eine qualitativ hochwertige 
                            Durchführung der Reinigungsdienstleistung, ganz gleich, ob täglich oder in fest definierten Intervallen.</p>
                        </div>
                      </div>
                      <div class="col-md-6 mt-4">
                        <div class="icon-box">
                            <i class="fas fa-tint"></i>
                          <h2>Grundreinigung</h2>
                          <p>Unter einer Grundreinigung versteht man eine besonders gründliche Reinigung, bei der auch schwer zugängliche Stellen wie Ecken, 
                            Nischen und Rillen gereinigt werden. Bei der Grundreinigung werden haftende, hartnäckige Verschmutzungen und Schmutzkrusten beseitigt. 
                            Eine Grundreinigung wird im Allgemeinen nur in größeren Zeitabständen durchgeführt und ist eine ideale Ergänzung zur Unterhaltsreinigung.</p>
                        </div>
                      </div>
                      <div class="col-md-6 mt-4">
                        <div class="icon-box">
                            <i class="fas fa-leaf"></i>
                          <h2>Gartenarbeiten</h2>
                          <p>Es genügt nicht nur, die Immobilie an sich in Schuss zu halten. Zu einem gepflegten Gesamtbild zählt auch eine perfekt gepflegte Gartenanlage. 
                            Unser Gartenservice in Esslingen ist hierfür genau der richtige Ansprechpartner. 
                            Lassen sie sich von unseren gut geschulten und motivierten Mitarbeitern die Arbeit abnehmen.</p>
                        </div>
                      </div>
                  </div>
                </div>
              </section>
              <section id="contact" class="contact section-bg">
                <div class="container">
                  <div class="section-title">
                    <h2>Kontakt</h2>
                 </div>
                  <div class="row mt-1 justify-content-center">
                    <div class="col-lg-10">
                      <div class="info-wrap">
                        <div class="row">
                          <div class="col-lg-4 info">
                            <i class="fas fa-map-marker-alt"></i>
                            <h3>Adresse:</h3>
                            <p>Stettener Straße 133<br/>73732 Esslingen am Neckar</p>
                          </div>
                          <div class="col-lg-4 info mt-4 mt-lg-0">
                            <i class="far fa-envelope"></i>
                            <h4>Email:</h4>
                            <a href="mailto:h.guel@-dienstleistungen.de">
                              <p>h.guel@-dienstleistungen.de</p>
                            </a>
                            <a href="mailto:g.guel@-dienstleistungen.de">
                              <p>g.guel@-dienstleistungen.de</p>
                            </a>
                          </div>
                          <div class="col-lg-4 info mt-4 mt-lg-0">
                            <i class="fas fa-phone"></i>
                            <h4>Telefon:</h4>
                            <a href="tel:+497116721325">
                              <p>+49 (0)711 6721325</p>
                            </a>
                            <a href="tel:+491736741448">
                              <p>+49 (0)173 6741448</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>          
                  </div>    
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.1017326145466!2d9.315401315821434!3d48.76085351552312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799c6c35b8e39f1%3A0x6aeec3acd4fd06eb!2sG%C3%BCl-Dienstleistungen!5e0!3m2!1sde!2sde!4v1640522692408!5m2!1sde!2sde" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen title="Standort Gül Dienstleistungen" loading="lazy"></iframe> */}
                </div>
              </section>
        </main>
        <footer id="footer">
            <div class="container d-mx-flex py-4">
              <div class="me-md-auto text-center text-md-center">
                <div class="footer-links">
                    <LinkR to='/imprint' exact >Impressum </LinkR> |
                    <LinkR to='/data' exact > Datenschutz</LinkR>                
                </div>
                <div class="copyright">
                  &copy; Copyright <strong><span>Gül Dienstleistungen</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                  Designed by <img src={leWebsites} alt="le-websites"/>
                </div>
              </div>
            </div>
          </footer>
      </body>
      </html>
  )
};

export default Home;