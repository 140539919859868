import React from 'react';

import Imprint from '../components/imprintPage';

const ImprintPage = () => {

  return(
    <>
      <Imprint/>
    </>
    )
};

export default ImprintPage;