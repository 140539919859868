import React from 'react';

import Dataprotection from '../components/dataprotection';

const DataPage = () => {

  return(
    <>
      <Dataprotection/>
    </>

    )
};

export default DataPage;