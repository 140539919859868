import React from 'react';
import './App.css';
import Home from './pages';
import DataPage from './pages/dataProtection';
import ImprintPage from './pages/imprint';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} exact />
          <Route path='/data' element={<DataPage />} exact />
          <Route path='/imprint' element={<ImprintPage />} exact />
        </Routes>
      </Router>
    </>
  );
}

export default App;
