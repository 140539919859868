import React from 'react';

import HomePage from '../components/mainPage/index';


const Home = () => {

  return(
    <>
      <HomePage/>
    </>
  )
};

export default Home;