import React from 'react';

import guelLogo from '../../logo/guelLogo.webp';
import leWebsites from '../../logo/leWebsites.png';
import '../../fontawesome/css/all.min.css';
import '../../bootstrap/css/bootstrap.min.css';
import {Link as LinkR} from 'react-router-dom';


const ImprintPage = () => {

  return(
    <html>
        <body> 
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
            <div class="logo me-auto">
                <img src={guelLogo} alt="guel dienstleistungen logo" class="img-fluid"/>
            </div>
            <nav id="navbar" class="navbar order-last order-lg-0">
                <ul>
                    <li><LinkR class="nav-link scrollto active" to='/' exact >Home</LinkR></li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
            </div>
        </header>
        <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
            <div class="container text-center text-md-left">
              <img src={guelLogo} alt="logo guel dienstleistungen" class="img-fluid"/>
            </div>
          </section> 
        <main id="main"> 
            <div id="home" class="home">
                <div class="container">
                  <div class="section-title">
                    <h2>Impressum</h2>
                    <p>
                      Angaben gemäß § 5 TMG
                      <br/> <br/>
                      Gül-Dienstleistungen 
                      Stettener Str. 132 
                      73732 Esslingen am Neckar
                      <br/> <br/>
                      Vertreten durch:
                      <br/>
                      Hüseyin Gül
                      <br/> <br/>
                      Kontakt:
                      <br/> <br/>
                      Telefon: +49 (0) 711 6721325 <br/>
                      Mobil: +49 (0) 174 2148628 <br/>
                      Fax: +49 (0) 711 6721326 <br/>
                      E-Mail: info@guel-dienstleistungen.de <br/>
                      <br/> <br/>
                      Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: 59150/5640
                      <br/> <br/>
                      Haftungsausschluss:
                      Haftung für Inhalte
                      <br/> <br/>
                      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, 
                      Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. 
                      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
                      Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde 
                      Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. 
                      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. 
                      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. 
                      Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                      <br/> <br/>
                      Haftung für Links
                      <br/> <br/>
                      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
                      Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. 
                      Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
                      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. 
                      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. 
                      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. 
                      Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                      <br/> <br/>
                      Dieses Impressum wurde mit dem Impressum Generator der Kanzlei Hasselbach - Spezialanwälte für Trennungsunterhalt - erstellt
                    </p>
                  </div>
                </div>
              </div>
        </main>
        <footer id="footer">
            <div class="container d-mx-flex py-4">
              <div class="me-md-auto text-center text-md-center">
                <div class="footer-links">
                    <LinkR to='/imprint' exact >Impressum </LinkR> |
                    <LinkR to='/data' exact > Datenschutz</LinkR> 
                </div>
                <div class="copyright">
                  &copy; Copyright <strong><span>Gül Dienstleistungen</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                  Designed by <img src={leWebsites} alt="le websites"/>
                </div>
              </div>
            </div>
          </footer>
        </body>
    </html>
    )
};

export default ImprintPage;