import React from 'react';

import guelLogo from '../../logo/guelLogo.webp';
import leWebsites from '../../logo/leWebsites.png';
import '../../fontawesome/css/all.min.css';
import '../../bootstrap/css/bootstrap.min.css';
import {Link as LinkR} from 'react-router-dom';

const DataPage = () => {

  return(
<html>
        <body> 
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
            <div class="logo me-auto">
                <img src={guelLogo} alt="guel dienstleistungen logo" class="img-fluid"/>
            </div>
            <nav id="navbar" class="navbar order-last order-lg-0">
                <ul>
                <li><LinkR class="nav-link scrollto active" to='/' exact >Home</LinkR></li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
            </div>
        </header>
        <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
            <div class="container text-center text-md-left">
              <img src={guelLogo} alt="logo guel dienstleistungen" class="img-fluid"/>
            </div>
          </section> 
        <main id="main"> 
            <div id="home" class="home">
                <div class="container">
                  <div class="section-title">
                    <h2>Datenschutz</h2>
                    <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
<br/>
<p><strong>Benennung der verantwortlichen Stelle</strong></p>
<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<br/>
<p> Gül-Dienstleistungen 
        Stettener Str. 132 
        73732 Esslingen am Neckar
        <br/> <br/>
        Vertreten durch:
        <br/>
        Hüseyin Gül
        <br/> <br/>
        Kontakt:
        <br/> <br/>
        Telefon: +49 (0) 711 6721325 
        <br/>
        Mobil: +49 (0) 174 2148628 
        <br/>
        Fax: +49 (0) 711 6721326 
        <br/>
        E-Mail: info@guel-dienstleistungen.de 
        <br/>
</p>
<p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der 
  Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
<br/>
<p>
Datenschutz
<br/> <br/>
Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, 
erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht 
ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. 
Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, 
etwa durch Spam-Mails, vor.
<br/> <br/>
Google Analytics
<br/> <br/>
Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google''). Google Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
<br/> <br/>
Google AdSense
<br/> <br/>
Diese Website benutzt Google Adsense, einen Webanzeigendienst der Google Inc., USA (''Google''). 
Google Adsense verwendet sog. ''Cookies'' (Textdateien), die auf Ihrem Computer gespeichert werden und die eine Analyse der 
Benutzung der Website durch Sie ermöglicht. Google Adsense verwendet auch sog. ''Web Beacons'' (kleine unsichtbare Grafiken) zur 
Sammlung von Informationen. Durch die Verwendung des Web Beacons können einfache Aktionen wie der Besucherverkehr auf der 
Webseite aufgezeichnet und gesammelt werden. Die durch den Cookie und/oder Web Beacon erzeugten Informationen über Ihre 
Benutzung diese Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und dort gespeichert. 
Google wird diese Informationen benutzen, um Ihre Nutzung der Website im Hinblick auf die Anzeigen auszuwerten, 
um Reports über die Websiteaktivitäten und Anzeigen für die Websitebetreiber zusammenzustellen und um weitere mit der 
Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen 
gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. 
Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Das Speichern von Cookies auf Ihrer 
Festplatte und die Anzeige von Web Beacons können Sie verhindern, indem Sie in Ihren Browser-Einstellungen ''keine Cookies akzeptieren'' 
wählen (Im MS Internet-Explorer unter ''Extras - Internetoptionen - Datenschutz - Einstellung''; im Firefox unter ''Extras - Einstellungen - 
Datenschutz - Cookies''); wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser 
Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen 
Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
</p>
<p><strong>Widerruf Ihrer Einwilligung <br/> zur Datenverarbeitung</strong></p>
<p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. 
  Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. 
  Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
<br/>
<p><strong>Recht auf Beschwerde bei der <br/> zuständigen Aufsichtsbehörde</strong></p>
<p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. 
  Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, 
  in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
</p>
<p>
  <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>
</p>
<br/>
<p><strong>Recht auf Datenübertragbarkeit</strong></p>
<p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, 
  an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. 
  Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
<br/>
<p><strong>Recht auf Auskunft, Berichtigung, <br/> Sperrung, Löschung</strong></p>
<p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre 
  gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. 
  ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema 
  personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
<br/>
<p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
<p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, 
  nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. 
  Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
<br/>
<p><strong>Google Web Fonts</strong></p>
<p>Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
<p>Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, 
  unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. 
  Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen 
  Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. 
  Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
  Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.</p>
<p>Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. 
  Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.</p>
<p>Einzelheiten über Google Web Fonts finden Sie unter: </p>
  <br/>
  <a href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts#AboutPlace:about</a> 
  <br/>
  <p>und weitere Informationen in den Datenschutzbestimmungen von Google: </p>
  <br/>
  <a href="https://policies.google.com/privacy/partners?hl=de">https://policies.google.com/privacy/partners?hl=de</a>
  <br/>
            <p>Quelle: Datenschutz-Konfigurator von </p>
              <br/>
            <small> <a href="http://www.mein-datenschutzbeauftragter.de">mein-datenschutzbeauftragter.de</a></small>
                  </div>
                </div>
              </div>
        </main>
        <footer id="footer">
            <div class="container d-mx-flex py-4">
              <div class="me-md-auto text-center text-md-center">
                <div class="footer-links">
                    <LinkR to='/imprint' exact >Impressum </LinkR> |
                    <LinkR to='/data' exact > Datenschutz</LinkR> 
                </div>
                <div class="copyright">
                  &copy; Copyright <strong><span>Gül Dienstleistungen</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                  Designed by <img src={leWebsites} alt="le websites"/>
                </div>
              </div>
            </div>
          </footer>
        </body>
    </html>

    )
};

export default DataPage;